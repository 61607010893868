import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const legacy = () => (
    <Layout>
      <SEO title="Bobcat Legacy" />
   
      <div className="Herosub batslogo">
  <div className="HeroGroupsmall">
    <p>&nbsp;</p>
<h1>Bobcat Legacy</h1></div>
  </div>

<div className="mainContent">
   

<div className="coachGrid">
<div>
    <h3>Justin Brown</h3>
    <p>Harford Community College</p>
    <p>2016 Class</p>
  </div>
  <div>
    <h3>Blake Coleman</h3>
    <p>Maryland Eastern Shore</p>
    <p>2016 Class</p>
  </div>
  <div>
    <h3>Anthony Febo</h3>
    <p>Christopher Newport University</p>
    <p>2017 Class</p>
  </div>
  <div>
    <h3>Dominque Hoyle</h3>
    <p>Juniata College</p>
    <p>2018 Class</p>
  </div>
  <div>
    <h3>Mathew Celio</h3>
    <p>Paul D Camp Community College</p>
    <p>2018 Class</p>
  </div>
  <div>
    <h3>Anthony Kettner</h3>
    <p>Paul D. Camp Community College</p>
    <p>2018 Class</p>
  </div>
  <div>
    <h3>Marty Neal</h3>
    <p>Harford Community College</p>
    <p>2018 Class</p>
  </div>
  <div>
    <h3>Joshua Brown</h3>
    <p>Harford Community College</p>
    <p>2018 Class</p>
  </div>
  <div>
    <h3>Zach Atencio</h3>
    <p>Thomas Nelson Community College</p>
    <p>2018 Class</p>
  </div>
  <div>
    <h3>Blake Gatewood</h3>
    <p>Prince George’s Community College</p>
    <p>2018 Class</p>
  </div>
  <div>
    <h3>Ethan Zimmerman</h3>
    <p>Shenandoah University</p>
    <p>2019 Class</p>
  </div>
  <div>
    <h3>Adian Bell</h3>
    <p>Mount Aloysius College</p>
    <p>2019 Class</p>
  </div>


</div>
    </div>
    </Layout>
  )
  
  export default legacy